/** @format */

import React, { useState } from "react";
import copy from "clipboard-copy";
import { Link } from "react-router-dom";

const Buy = () => {
  const [show, setShow] = useState("");

  const handleClick = () => {
    const textToCopy = "";
    copy(textToCopy);
    setShow("Copied to clipboard");
  };
  return (
    <>
      <div id='buy' className=' lg:px-4 py-28 bg-[#000] bg_img overflow-hidden'>
        <div className='container-fluid '>
          <div className='flex justify-end'>
            <img
              src='assets/images/rotate.png'
              className='lg:w-24 w-12 image'
              alt=''
            />
          </div>
          <div className='grid lg:grid-cols-2'>
            <div>
              {/* <img src="assets/images/buy.png" alt="" /> */}
              <h4 className='font-carter text-5xl  font-bold text-[#3c80fb]   stroke-text leading-snug tracking-tight'>
                Become a Popeye Holder and join Spinach-Powered Economy!{" "}
              </h4>
              <p className='text-white font-rubik mt-3'>
                Owning Popeye coin tokens opens a realm of opportunities.
                Following are the ways to maximize your experience
              </p>
              <h4 className='font-carter text-2xl  font-bold text-[#3c80fb]   stroke-text leading-snug tracking-tight'>
                Relax and Hold
              </h4>
              <p className='text-white font-rubik mt-3'>
                Let your tokens work and earn passive income for you. It's like
                Popeye is growing better without spinach.
                <br />
                <br />
                Contribute to the smooth sailing of the Project Popeye ecosystem
                by providing liquidity. In return, you will be rewarded with
                handsome yields, and your token gain more popularity.
              </p>
            </div>
            <div className='flex justify-center'>
              <img src='assets/images/1.png' className='w-1/2' alt='' />
            </div>
          </div>
          {/* <div className="grid lg:grid-cols-3 gap-6">
            <img src="assets/images/b1.png" alt="" />
            <img src="assets/images/b2.png" alt="" />
            <img src="assets/images/b3.png" alt="" />
          </div> */}
          <div className='py-12 flex items-center flex-wrap justify-center space-y-2 lg:justify-between'>
            <h2 className='text-white text-3xl font-carter font-bold m-0'>
              Contract Address
            </h2>
            <div className='flex items-center justify-center flex-wrap border-[1px] border-[#3c80fb] px-3 lg:py-1 py-2 rounded-full lg:space-x-16 lg:space-y-0 space-y-2'>
              <button
                onClick={handleClick}
                className='text-white font-rubik font-medium lg:text-lg text-xs m-0'>
                - - - - - - - - - - - - -- -
              </button>
              <Link
                to='#'
                className='cursor-pointer font-carter no-underline justify-center  text-white lg:text-md text-sm uppercase px-3 py-2 nav_btn'>
                buy $pope
              </Link>
            </div>
            <div className='flex space-x-4'>
              <img
                src='assets/images/Ellipse-2468.png'
                className='w-16 aspect-square object-contain'
                alt=''
              />
              <img
                src='assets/images/Ellipse-2469.png'
                className='w-16 aspect-square object-contain'
                alt=''
              />
              <img
                src='assets/images/Raydium.svg'
                className='w-16 aspect-square object-contain'
                alt=''
              />
              <img
                src='assets/images/Ellipse-2470.png'
                className='w-16 aspect-square object-contain'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Buy;
