import React from "react";

const Roadmap = () => {
  return (
    <>
      <div id="roadmap" className=" px-4 py-28 bg-[#000] overflow-hidden">
        <div className="container-fluid">
            <div className="flex justify-center relative">
                <img src="assets/images/roadmap.png" alt="" />
                <div className="absolute lg:left-0 top-14   animate-bounce">
                <img src="assets/images/7099149-1.png" className="w-20" alt="" />
                </div>
            </div>
            <div className="grid lg:grid-cols-3 gap-2 lg:py-20 py-14 relative">
                <div>
                    <img src="assets/images/p1.png" alt="" />
                    <ul className="text-white mt-3 p-0">
                        <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Project ideas</li>
                        </div>
                        <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Create a website for POPEYE</li>
                        </div>
                        <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Build Twitter community</li>
                        </div>
                        <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Build Telegram community</li>
                        </div>
                        <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Audit contract POPEYE</li>
                        </div>
                        {/* <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Presale on Pinksale</li>
                        </div> */}
                    </ul>
                </div>
                <div>
                    <img src="assets/images/p2.png" alt="" />
                    <ul className="text-white mt-3 p-0">
                         {/* <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Launch on Pancakeswap</li>
                        </div> */}
                         <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Renounce ownership $POPE contract</li>
                        </div>
                         <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">List on DEXs</li>
                        </div>
                         <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">List on CoinMarketcap</li>
                        </div>
                         <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">List on Coingecko</li>
                        </div>
                         <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Target 5000 holders</li>
                        </div>
                    </ul>
                </div>
                <div>
                    <img src="assets/images/p3.png" alt="" />
                    <ul className="text-white mt-3 p-0">
                        <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Connect more ambassadors</li>
                        </div>
                        <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Marketing campaign</li>
                        </div>
                        <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Partnerships</li>
                        </div>
                        <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Audit By Certik</li>
                        </div>
                        {/* <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">Release NFT collection</li>
                        </div> */}
                        <div className="flex items-center space-x-3 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} viewBox="0 0 19 20" fill="none"><circle cx="9.5" cy={10} r="7.5" fill="#D9D9D9" stroke="url(#paint0_linear_11861_626)" strokeWidth={4} /><defs><linearGradient id="paint0_linear_11861_626" x1="-6.13717" y1="6.63717" x2="6.13717" y2="25.6372" gradientUnits="userSpaceOnUse"><stop stopColor="#5B8DEF" /><stop offset={1} stopColor="#0063F7" /></linearGradient></defs></svg>
                        <li className="font-rubik text-lg ">First CEX listing</li>
                        </div>
                    </ul>
                </div>
                <div className="absolute right-0 bottom-0 animate-bounce">
                    <img src="assets/images/rocket.png" className="w-20 lg:w-56" alt="" />
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
