/** @format */

import React from "react";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const Community = () => {
  return (
    <>
      <div id='community' className=' lg:px-4 py-16 bg-[#000] overflow-hidden'>
        <div className='container-fluid'>
          <div className='flex justify-center bg_img2 '>
            <div className=' grid lg:grid-cols-2 lg:w-3/4'>
              <div className='flex flex-col space-y-6 lg:mt-36 mt-12'>
                <div>
                  <h4 className='font-carter text-4xl  font-bold text-white'>
                    More Than Just a Meme Coin
                  </h4>
                </div>
                <p className='text-white font-rubik'>
                  Project Popeye isn't just another meme coin. It's a
                  community-driven ecosystem where you chart the course. You can
                  unleash your inner sailor here as Popeye project comes with an
                  opportunity to create long lasting memories in the digital
                  age!
                </p>
                <div className='flex space-x-4'>
                  <a href="https://twitter.com/PopeyeSolana" target="blank"  rel="noreferrer">
                    <FaTwitter
                      className='bg-white rounded-full p-2'
                      size={38}
                      color='#000'
                    />
                  </a>
                  <a href="https://t.me/Popeye_Solana" target="blank"  rel="noreferrer">
                    <FaTelegramPlane
                      className='bg-white rounded-full p-2'
                      size={38}
                      color='#000'
                    />
                  </a>
                </div>
              </div>
              <div className='flex lg:justify-end'>
                <img src='assets/images/c2.png' alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Community;
