/** @format */

import React from "react";
import Marquee from "react-marquee-slider";

const data = [
  {
    image: "assets/images/raydium-ray.png",
  },
  {
    image: "assets/images/dexscreener.png",
  },
  {
    image: "assets/images/r3.png",
  },
  {
    image: "assets/images/r4.png",
  },
  {
    image: "assets/images/r5.png",
  },
];

const data2 = [
  {
    image: "assets/images/r6.png",
  },
  {
    image: "assets/images/r7.png",
  },
  {
    image: "assets/images/r8.png",
  },
  {
    image: "assets/images/r9.png",
  },
  {
    image: "assets/images/r10.png",
  },
];

const Partner = () => {
  return (
    <>
      <div id='partner' className=' px-4 py-2 bg-[#000] overflow-hidden'>
        <div className='container-fluid'>
          <div className='flex justify-center '>
            <img src='assets/images/partner.png' alt='' />
          </div>

          <Marquee>
            {data.map((item, index) => (
              <div key={index}>
                <img src={item.image} className='lg:w-3/4 w-44' alt='' />
              </div>
            ))}
          </Marquee>

          {/* <Marquee direction='right'>
            {data2.map((item, index) => (
              <div key={index}>
                <img src={item.image} className='lg:w-3/4 w-44' alt='' />
              </div>
            ))}
          </Marquee> */}
        </div>
      </div>
    </>
  );
};

export default Partner;
