/** @format */

import React, { useState } from "react";
// import { Link } from "react-router-dom";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <nav className=' px-4 py-2 bg-[#000]  '>
        <div className='container-fluid mx-auto flex justify-between items-center'>
          <div className='lg:flex justify-end items-center gap-3 flex-wrap'>
            <img
              src='assets/images/logo.png'
              className='lg:w-24 w-2/4 '
              alt=''
            />
            <h4 className='font-carter text-4xl text-center font-bold text-white'>
              POPEYE
            </h4>
          </div>
          <div className='hidden md:flex  justify-center md:justify-end space-x-8 '>
            <Link
              activeClass='active'
              to='hero'
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className='text-[#a7a9b8] cursor-pointer hover:text-[#323f34] font-rubik  text-lg no-underline capitalize'>
              home
            </Link>
            <Link
              activeClass='active'
              to='buy'
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className='text-[#a7a9b8] cursor-pointer hover:text-[#323f34] font-rubik  text-lg no-underline '>
            Relax and Hold
            </Link>
            <Link
              activeClass='active'
              to='tokenomics'
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className='text-[#a7a9b8] cursor-pointer hover:text-[#323f34] font-rubik  text-lg no-underline capitalize'>
              Tokenomics
            </Link>
            <Link
              activeClass='active'
              to='community'
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className='text-[#a7a9b8] cursor-pointer hover:text-[#323f34] font-rubik  text-lg no-underline capitalize'>
              Community
            </Link>
            <Link
              activeClass='active'
              to='roadmap'
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className='text-[#a7a9b8] cursor-pointer hover:text-[#323f34] font-rubik  text-lg no-underline capitalize'>
              Roadmap
            </Link>
            <Link
              activeClass='active'
              to='partner'
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className='text-[#a7a9b8] cursor-pointer hover:text-[#323f34] font-rubik  text-lg no-underline capitalize'>
              Partners
            </Link>
            {/* <Link
              activeClass='active'
              to='whitepaper'
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className='text-[#a7a9b8] cursor-pointer hover:text-[#323f34] font-rubik  text-lg no-underline capitalize'>
              whitepaper
            </Link> */}
          </div>
          <div className='lg:flex justify-between items-center hidden'>
            <Link
              to='#'
              className=' cursor-pointer font-carter no-underline text-white text-2xl uppercase px-3 py-2 nav_btn'>
              Buy now
            </Link>
          </div>
          <div className='md:hidden '>
            {/* Mobile menu button (Hamburger icon) */}
            <button className='text-[#fff]' onClick={toggleMenu}>
              <svg
                className='h-6 w-6'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path d='M4 6h16M4 12h16m-7 6h7'></path>
              </svg>
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className='md:hidden lg:w-96'>
            <div className='flex flex-col text-center space-y-2 py-3 rounded-md '>
              <Link
                activeClass='active'
                to='hero'
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className='text-[#a7a9b8] font-rubik text-sm no-underline capitalize'>
                home
              </Link>
              <Link
                activeClass='active'
                to='buy'
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className='text-[#a7a9b8] font-rubik text-sm no-underline capitalize'>
                how to buy
              </Link>
              <Link
                activeClass='active'
                to='tokenomics'
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className='text-[#a7a9b8] font-rubik text-sm no-underline capitalize'>
                tokenomics
              </Link>
              <Link
                activeClass='active'
                to='community'
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className='text-[#a7a9b8] font-rubik text-sm no-underline capitalize'>
                community
              </Link>
              <Link
                activeClass='active'
                to='roadmap'
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className='text-[#a7a9b8] font-rubik text-sm no-underline capitalize'>
                roadmap
              </Link>
              <Link
                activeClass='active'
                to='partner'
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className='text-[#a7a9b8] font-rubik text-sm no-underline capitalize'>
                partner
              </Link>
              {/* <Link
                activeClass='active'
                to='whitepaper'
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className='text-[#a7a9b8] font-rubik text-sm no-underline capitalize'>
                whitepaper
              </Link> */}
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Header;
