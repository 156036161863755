/** @format */

import React from "react";

const Sections = () => {
  return (
    <>
      <div id='whitepaper' className='  bg-[#000] overflow-hidden'>
        <img
          src='assets/images/prefooter.png'
          className='m-auto w-1/3'
          alt=''
        />
      </div>
    </>
  );
};

export default Sections;
