/** @format */

import React from "react";
import { Link } from "react-router-dom";
import Marquee from "react-marquee-slider";

const data = [
  {
    title: "$pope",
  },
  {
    title: "$pope",
  },
  {
    title: "$pope",
  },
  {
    title: "$pope",
  },
  {
    title: "$pope",
  },
  {
    title: "$pope",
  },
  {
    title: "$pope",
  },
  {
    title: "$pope",
  },
];

const Hero = () => {
  return (
    <>
      <div id='hero' className=' px-4 py-2 bg-[#000]  overflow-hidden'>
        <div className='container-fluid '>
          <div className='grid lg:grid-cols-2 gap-12 mt-3'>
            <div className='flex flex-col justify-center space-y-4'>
              <div>
                <img src='assets/images/welcome-to.png' alt='' />
              </div>
              <div>
                <img src='assets/images/hero1.png' alt='' />
              </div>
              <p className='text-white font-rubik text-lg'>
                Popeye's adventures have sailed into a new era! he's paving a
                way for crypto’s success.! Popeye’s coin puts the power in your
                can - it's run by YOU. Vote on projects future and earn rewards
                for being part of the crew! Nostalgic feel?
                <br />
                <br />
                Remember the thrill of Popeye’s adventures? The magic is back
                with exclusive events, contests, and animated content! Backed by
                secure Solana blockchain, Popeye ensures safety with smart
                contract audits and transparency.
              </p>
              <div className='relative'>
                <div className='flex items-center flex-wrap lg:space-x-6  space-y-2'>
                  <Link
                    to='#'
                    className='cursor-pointer font-carter no-underline text-white text-2xl uppercase px-3 py-2 nav_btn'>
                    fill your bags
                  </Link>
                  <Link
                    to='#'
                    className='cursor-pointer font-carter no-underline text-white text-2xl uppercase px-3 py-2 border-[1px] rounded-full border-[#3c80fb]'>
                    view chart
                  </Link>
                </div>
                {/* <div className='lg:mt-[-5px] animate-bounce'>
                  <img src='assets/images/hero4.png' alt='' />
                </div> */}
              </div>
            </div>

            <div>
              <div className='flex justify-end relative animate-bounce'>
                <img
                  src='assets/images/hero2.png'
                  className='lg:w-4/5'
                  alt=''
                />
                <div className='absolute bottom-[-50px]  flex justify-center'>
                  <img src='assets/images/hero3.png' className='w-3/2' alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='marque  '>
        <Marquee>
          {data.map((item, index) => (
            <div key={index} className='flex'>
              {/* Your content goes here */}
              <h2 className='text-white font-carter text-5xl mx-4  font-bold uppercase py-4'>
                {item.title}
              </h2>
            </div>
          ))}
        </Marquee>
      </div>
    </>
  );
};

export default Hero;
