/** @format */

import React from "react";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <>
      <footer
        className='footer text-white '
        style={{ backgroundColor: "#000" }}>
        <div className='container-fluid'>
          <h4 className='font-carter text-4xl text-center font-bold'>POPEYE</h4>
          <p className='text-center mx-auto font-rubik lg:text-lg max-w-xl  '>
            The more you participate, the stronger the ship becomes. So, be a
            part of the crew, get your rewards, and help Popeye vision going
            above and beyond!
          </p>
          <div className='flex justify-center items-center space-x-4 py-6'>
          <a href="https://twitter.com/PopeyeSolana" target="blank"  rel="noreferrer">
                    <FaTwitter
                      className='bg-white rounded-full p-2'
                      size={38}
                      color='#000'
                    />
                  </a>
                  <a href="https://t.me/Popeye_Solana" target="blank"  rel="noreferrer">
                    <FaTelegramPlane
                      className='bg-white rounded-full p-2'
                      size={38}
                      color='#000'
                    />
                  </a>
          </div>
          <div className='flex justify-center flex-wrap items-center space-x-3'>
            <Link
              to='hero'
              className='text-[#fff] cursor-pointer hover:text-[#323f34] font-rubik  text-md font-bold no-underline capitalize'>
              home
            </Link>
            <Link
              to='buy'
              className='text-[#fff] cursor-pointer hover:text-[#323f34] font-rubik  text-md font-bold no-underline '>
            Relax and Hold
            </Link>
            <Link
              to='tokenomics'
              className='text-[#fff] cursor-pointer hover:text-[#323f34] font-rubik  text-md font-bold no-underline capitalize'>
              Tokenomics
            </Link>
            <Link
              to='community'
              className='text-[#fff] cursor-pointer hover:text-[#323f34] font-rubik  text-md font-bold no-underline capitalize'>
              Community
            </Link>
            <Link
              to='roadmap'
              className='text-[#fff] cursor-pointer hover:text-[#323f34] font-rubik  text-md font-bold no-underline capitalize'>
              Roadmap
            </Link>
            <Link
              to='partner'
              className='text-[#fff] cursor-pointer hover:text-[#323f34] font-rubik  text-md font-bold  no-underline capitalize'>
              Partners
            </Link>
          </div>
          <div className='py-4'>
            <p className='text-center m-0 font-rubik text-[#506453]'>
              All Rights Reserved 2024
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
