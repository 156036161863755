import React from 'react'
import Hero from '../components/Elements/Hero'
import Buy from '../components/Elements/Buy'
import Tokenomics from '../components/Elements/Tokenomics'
import Sections from '../components/Elements/Sections'
import Community from '../components/Elements/Community'
import Roadmap from '../components/Elements/Roadmap'
import Partner from '../components/Elements/Partner'

const Home = () => {
  return (
    <>
      <Hero/>
      <Buy/>
      <Tokenomics/>
      <Community/>
      <Roadmap/>
      <Partner/>
      <Sections/>
    </>
  )
}

export default Home