/** @format */

import React from "react";

const Tokenomics = () => {
  return (
    <>
      <div id='tokenomics' className=' lg:px-4  bg-[#000] overflow-hidden'>
        <div className='container-fluid '>
          <div className='flex justify-center relative'>
            <img src='assets/images/token.png' className='' alt='' />
            <div className='absolute lg:right-[-30px] top-14 animate-bounce '>
              <img
                src='assets/images/009_W-1.png'
                className='lg:w-56 w-14'
                alt=''
              />
            </div>
          </div>
          <h4 className='text-center py-4 text-white font-rubik font-thin text-xl'>
            Total Suply{" "}
            <span className='font-bold text-2xl'>100,000,000,000 $POPE</span>
          </h4>
          <div className='flex justify-center '>
            {/* <img src='assets/images/token2.png' alt='' /> */}
          </div>
          <div className='flex justify-center py-5'>
            <img src='assets/images/color.png' alt='' />
          </div>
          <div className='flex justify-center py-2'>
            {/* <img src='assets/images/5.png' alt='' /> */}
            <h4 className='font-carter text-5xl  font-samibold text-[#3c80fb]   stroke-text leading-snug tracking-tight'>
              Tax: 0/0
            </h4>
          </div>
          {/* <div className='flex justify-center items-center lg:space-x-6 space-x-2 py-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={19}
              height={20}
              viewBox='0 0 19 20'
              fill='none'>
              <circle
                cx='9.5'
                cy={10}
                r='7.5'
                fill='#D9D9D9'
                stroke='url(#paint0_linear_11861_626)'
                strokeWidth={4}
              />
              <defs>
                <linearGradient
                  id='paint0_linear_11861_626'
                  x1='-6.13717'
                  y1='6.63717'
                  x2='6.13717'
                  y2='25.6372'
                  gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#5B8DEF' />
                  <stop offset={1} stopColor='#0063F7' />
                </linearGradient>
              </defs>
            </svg>

            <p className='text-[#5b8def] font-rubik lg:text-xl text-sm mb-0'>
              1% <span className='text-white'>Reflections</span>
            </p>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={19}
              height={20}
              viewBox='0 0 19 20'
              fill='none'>
              <circle
                cx='9.5'
                cy={10}
                r='7.5'
                fill='#D9D9D9'
                stroke='url(#paint0_linear_11861_626)'
                strokeWidth={4}
              />
              <defs>
                <linearGradient
                  id='paint0_linear_11861_626'
                  x1='-6.13717'
                  y1='6.63717'
                  x2='6.13717'
                  y2='25.6372'
                  gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#5B8DEF' />
                  <stop offset={1} stopColor='#0063F7' />
                </linearGradient>
              </defs>
            </svg>
            <p className='text-[#5b8def] font-rubik lg:text-xl text-sm mb-0'>
              4% <span className='text-white'>Marketing & CEX listing</span>
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Tokenomics;
